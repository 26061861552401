export const FILTERS_ARGS = {
  SEARCH: 'search',
  EXAM_DATE_AFTER: 'exam_date_after',
  EXAM_DATE_BEFORE: 'exam_date_before',
  EXAM_LOCATION: 'exam_location',
  CBR_USER: 'cbr_user',
  COURSE_TYPE: 'course_type'
};

export const VIOLET_COLOR = '#791571';

export const CM_HEADERS = [
  {text: '', value: 'edit', class: 'app-table__h_small cell-btns', sortable: false},
  {text: '№', value: 'number', class: 'app-table__h_small cell-index', align: 'left', sortable: false},
  {text: 'Color', value: 'color', class: 'app-table__h_small cell-index', align: 'left', sortable: true},
  {text: 'Placed/Paid', value: 'placed', align: 'left', class: 'nowrap cell-placed-paid', sortable: false},
  {text: 'Name', value: 'candidateName', align: 'left', class: 'cell-name'},
  {text: 'CDN', align: 'left', sortable: false, value: 'candidateNumber', class: 'nowrap cell-number'},
  {text: 'DOB/Phone', value: 'candidateBirthday', class: 'nowrap cell-bday', align: 'center'},
  {text: 'CBR', value: 'accountUsername', align: 'left', class: 'cell-cbr'},
  {text: 'App ID', value: 'appId', align: 'center', class: 'cell-appid text-center', sortable: false},
  {text: 'Payment', value: 'payment', sortable: false, class: 'cell-payment'},
  {text: 'Reserved Info', value: 'reserved', align: 'center', class: 'cell-reserved', sortable: true},
  {text: 'Journey Name', value: 'journey', align: 'center', sortable: false, class: 'cell-journey'},
  {text: 'Product', value: 'productName', align: 'left', class: 'cell-product', sortable: true},
  {text: 'Language', value: 'language', align: 'left', class: 'cell-product', sortable: true},
  {text: 'Status', value: 'status', align: 'left', class: 'cell-status'},
  {text: 'Course type', value: 'course-type', align: 'left', class: 'nowrap cell-course-type', sortable: false},
  {text: '', value: 'bov', align: 'center', class: 'nowrap cell-email', sortable: false},
  {text: 'Confirmed Courses', value: 'mainDate', align: 'left', class: 'cell-dates', sortable: false},
  {text: 'Reservation dates', value: 'requests', align: 'left', class: 'cell-reservation', sortable: false},
  {text: 'Note', value: 'note', align: 'center', sortable: false, class: 'cell-note'}
];

export const TRANSPORT_TYPE = {
  BUS: 'bus',
  TAXI: 'taxi',
  LOPEND: 'lopend',
  ENOENT: 'enoent',
  ZELF_EXAM: 'zelf_exam'
};

export const TRANSPORT_TYPE_LIST = [TRANSPORT_TYPE.BUS, TRANSPORT_TYPE.TAXI, TRANSPORT_TYPE.LOPEND, TRANSPORT_TYPE.ZELF_EXAM];

export const CM_DATA_TEST = {
  COUNTS: {
    CANDIDATES_COUNT: 'candidates-count',
    WILL_COME: 'count-will-come',
    NOT_COME: 'counts-not-come',
    TOP_CBR: 'count-top-cbr',
    TOP_CBR_CURSS: 'count-top-cbr-curss',
    MIJN_CBR: 'count-mijn-cbr',
    SLOTS: 'count-slots',
    UNPAID: 'count-unpaid'
  }
};

export const COLORS = {
  primary: '#4e7fed',
  error: '#ff5252',
  warning: '#fb8c00',
  negative: '#758289'
};
